import React from "react"
import '../styles/index.scss'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'

const AlbumsPage = ({data}) => {
    const albums = data.allContentfulAlbum.edges
    return (
        <Layout>
            <div className="album-list">        
            {albums.map(({node: album}) => {
                return (<Link to={`/albums/${album.slug}`} key={album.id}>
                    <Img fluid={album.featuredImage.fluid} />
                    <h4>{album.title}</h4>
                </Link>)
            })}
            </div>
        </Layout>
    );
}

export default AlbumsPage;
export const query = graphql`
    query {
        allContentfulAlbum {
            edges {
                node {
                    id
                    title
                    slug    
                    featuredImage {
                        fluid(maxWidth: 500) {
                           ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`
